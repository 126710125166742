import React from "react";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ContentContainer from "../container/ContentContainer";

const ProductItem = ({ title, link, image, useTitle }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center space-y-5">
      <div className="w-full h-full">
        <Link to={link}>
          <GatsbyImage image={image} alt={title} className="w-full h-full" />
        </Link>
      </div>
      {useTitle === undefined ? (
        <p className="mb-5 font-semibold">{title}</p>
      ) : null}
      {useTitle ? (
        <Link to={link} className="text-actionBlue font-semibold underline">
        <p className="block sm:hidden">{title}</p>
        <p>See More</p>
      </Link>
      ) : (
        <Link to={link} className="text-actionBlue font-semibold underline">
          See More
        </Link>
      )}
    </div>
  );
};

const ProductGalleryNew = () => {
  const productImage = useStaticQuery(graphql`
    {
      hardwoodLumber: file(relativePath: { eq: "hardwood-lumber.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
      hardwoodPlywood: file(relativePath: { eq: "hardwood-plywood.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
      lacqueredBoards: file(relativePath: { eq: "lacquered-boards.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
      thermofusedLaminates: file(relativePath: { eq: "thermofused-laminates-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
      melamine: file(relativePath: { eq: "melamine.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
      mdf: file(relativePath: { eq: "mdf.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `);

  return (
    <ContentContainer
      containerClassName=""
      className="px-0 sm:px-4 flex flex-col items-center justify-center max-w-full relative mt-0 md:-mt-24 sm:mx-5 bg-white sm:bg-gray1 "
    >
      <div className="flex flex-col items-center justify-center w-full max-w-7xl pt-0 pb-10 sm:py-10">
        <div className="py-4 sm:py-0 mb-0 sm:mb-10 max-w-6xl w-full text-center sm:text-left bg-gray1 sm:bg-transparent">
          <h2>Our Products</h2>
        </div>
        <div className="flex items-center justify-center w-full mb-10">
          <svg width="20" height="10">
            <polyline
              points="0 0 10 10 20 0"
              className="stroke-gray1 fill-gray1"
            />
          </svg>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-10 px-28 sm:px-0">
          <ProductItem
            title="Hardwood Lumber"
            link="/products/hardwood-lumber"
            image={getImage(productImage.hardwoodLumber)}
          />
          <ProductItem
            title="Hardwood Plywood"
            link="/products/hardwood-plywood"
            image={getImage(productImage.hardwoodPlywood)}
          />
          <ProductItem
            title="Lacquered Panels"
            link="/products/lacquered-boards"
            image={getImage(productImage.lacqueredBoards)}
          />
          <ProductItem
            title="Thermofused Laminates"
            link="/products/thermofused-laminates"
            image={getImage(productImage.thermofusedLaminates)}
          />
          <ProductItem
            title="Melamine"
            link="/products/melamine-panels"
            image={getImage(productImage.melamine)}
          />
          <ProductItem
            title="MDF"
            link="/products/mdf"
            image={getImage(productImage.mdf)}
          />
        </div>
      </div>
    </ContentContainer>
  );
};

export default ProductGalleryNew;
