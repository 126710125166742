import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { FaPhoneAlt, FaRegWindowClose } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "gatsby";
import ButtonAction from "./ButtonAction";

const MobileMenuItem = ({ to, children, className, ariaLabel }) => {
  return (
    <>
      {to !== undefined ? (
        <Link
          to={to}
          className={`button-action ${className}`}
          activeClassName="bg-actionBlue text-white"
          aria-label={ariaLabel}
        >
          <div className="w-full pl-12 py-3 underline">{children}</div>
        </Link>
      ) : (
        <div className={className}>
          <div className="w-full pl-12 py-3">{children}</div>
        </div>
      )}
    </>
  );
};

export const NavBar = ({
  direction,
  children,
  className,
  logoClassName,
  supportMobile,
  isHome,
  isContact,
}) => {
  const ChildObj = React.Children.toArray(children);

  const NavLogoObj = ChildObj.find((c) => {
    return c.type.name === "NavLogo" || c.props.name === "NavLogo";
  });
  const NavItems = ChildObj.filter((c) => {
    return c.type.name === "NavItem" || c.props.name === "NavItem";
  });

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      window.scrollTo(0, 0);
    };

    if (showMenu) window.addEventListener("scroll", onScroll);
    else window.removeEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <>
      <div className="flex lg:hidden absolute z-40 top-8 right-6 w-8 h-8">
        <button
          className={`flex items-center justify-center rounded-md ${
            isHome ? "text-white" : "text-gray1"
          } focus:outline-none`}
          onClick={() => setShowMenu(true)}
          aria-label="Menu"
        >
          <GiHamburgerMenu className="text-3xl" />
        </button>
      </div>

      {/* MOBILE MENU */}
      <div
        className={`flex lg:hidden absolute top-0 w-full h-screen transition-all duration-500 ease-in-out bg-white ${
          showMenu ? "right-0" : "-right-screen"
        } ${
          direction === "right"
            ? "justify-start lg:justify-end"
            : "justify-start"
        } z-50`}
      >
        <button
          className="items-center justify-center absolute z-50 w-8 h-8 top-2 left-2 text-red-700 focus:outline-none"
          onClick={() => setShowMenu(false)}
          aria-label="Menu Close"
        >
          <FaRegWindowClose className="text-2xl" />
        </button>
        <div className="pt-12 flex flex-col w-full">
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/" ariaLabel="Home">
            HOME
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" ariaLabel="Products">
            PRODUCTS
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/hardwood-lumber" ariaLabel="Hardwood Lumber">
            Hardwood Lumber
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/hardwood-plywood" ariaLabel="Hardwood Plywood">
            Hardwood Plywood
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/lacquered-boards" ariaLabel="Lacquered Panels">
            Lacquered Panels
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/thermofused-laminates" ariaLabel="Thermofused Laminates">
            Thermofused Laminates
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/melamine-panels" ariaLabel="Melamine">
            Melamine
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/mdf" ariaLabel="MDF">
            MDF
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/about" ariaLabel="About">
            ABOUT
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/blog" ariaLabel="Blog">
            BLOG
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/forms" ariaLabel="Forms">
            FORMS
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/jobs" ariaLabel="Jobs">
            JOBS
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/contact" ariaLabel="Contact">
            CONTACT
          </MobileMenuItem>
        </div>
      </div>

      <div className={`${className}`}>
        <div className={logoClassName}>{NavLogoObj}</div>
        <div
          className={`${
            showMenu ? "w-screen h-screen lg:w-0 lg:h-0" : ""
          } absolute left-0 top-0 bg-black lg:bg-transparent bg-opacity-50 lg:static lg:flex-grow flex`}
        >
          <div
            className={`hidden sm:flex flex-row w-full pr-20 ${
              direction === "right"
                ? "justify-start lg:justify-end"
                : "justify-start"
            }`}
          >
            <div className="hidden lg:flex flex-row pt-10 gap-y-10">
              {NavItems}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex lg:flex-col items-center justify-center h-32">
          <ButtonAction
            href="tel:909-287-7906"
            newWindow={false}
            className={`flex items-center justify-center px-12 ${
              isHome
                ? "text-white text-lg border-2 border-white bg-black bg-opacity-30 hover:bg-opacity-75"
                : "text-white text-lg bg-actionBlue"
            } max-h-14`}
          >
            <FaPhoneAlt className="inline mr-4" />
            (909) 287-7906
          </ButtonAction>
          {isHome || isContact
            ?
            null
            :
            <div className="mt-3">
              <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" target="_blank" rel="noreferrer">1 Latitude Way, Corona, CA 92881</a>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export const NavItem = ({ children, link, className, ariaLabel }) => {
  return (
    <div
      className={
        className
          ? className
          : "flex justify-center items-center cursor-pointer px-1 py-4 font-sans text-sm"
      }
    >
      <Link
        className="button-action"
        to={link}
        activeClassName="text-actionBlue underline"
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    </div>
  );
};

export class NavLogo extends Component {
  render() {
    const { link } = { ...this.props };

    const ImageContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${this.props.image});
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: ${this.props.width !== undefined
        ? this.props.width
        : this.props.height !== undefined
        ? this.props.height
        : 0};
      height: ${this.props.height !== undefined
        ? this.props.height
        : this.props.width !== undefined
        ? this.props.width
        : 0};
    `;
    return (
      <Link to={link ? link : "/"} aria-label="National Wood Products logo">
        <ImageContainer>&nbsp;</ImageContainer>
      </Link>
    );
  }
}
