import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import BlogContainer from "../../components/container/BlogContainer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import htmlSerializer from "../../utils/htmlSerializer";

const SliceContent = ({ data, index }) => {
  if (!data || !data.internal || !data.internal.type) {
    console.warn(`Invalid slice data at index ${index}`, data);
    return null;
  }

  switch (data.internal.type) {
    case "PrismicBlogBodyImageGallery": {
      const image = getImage(data.primary.image.localFile);
      return (
        image && (
          <GatsbyImage
            key={`image${index}`}
            image={image}
            alt={data.primary.image.alt ? data.primary.image.alt : ""}
            className="mb-7"
          />
        )
      );
    }
    case "PrismicBlogBodyParagraphWithImageRight": {
      const image = getImage(data.primary.paragraph_image.localFile);
      return (
        <div key={`pImage${index}`}>
          {image && (
            <GatsbyImage
              width={300}
              image={image}
              alt={
                data.primary.paragraph_image.alt
                  ? data.primary.paragraph_image.alt
                  : ""
              }
              className="float-right ml-6 mb-6 w-full sm:max-w-sm md:max-w-md"
            />
          )}
          <RichText
            key={`div${index}`}
            render={data.primary.text.raw}
            htmlSerializer={htmlSerializer}
          />
        </div>
      );
    }
    case "PrismicBlogBodyHeader": {
      return (
        <RichText
          key={`div${index}`}
          render={data.primary.content.raw}
          htmlSerializer={htmlSerializer}
        />
      );
    }
    default: {
      return (
        <RichText
          key={`div${index}`}
          render={data.primary.content.raw}
          htmlSerializer={htmlSerializer}
        />
      );
    }
  }
};

const BlogPage = ({ data }) => {
  const title = data.prismicBlog.data.banner_title.text;
  const path = [title];
  const relatedContent = [];

  if (data.prismicBlog.data.related_content_1.uid)
    relatedContent.push(data.prismicBlog.data.related_content_1.uid);
  if (data.prismicBlog.data.related_content_2.uid)
    relatedContent.push(data.prismicBlog.data.related_content_2.uid);
  if (data.prismicBlog.data.related_content_3.uid)
    relatedContent.push(data.prismicBlog.data.related_content_3.uid);

  const image = getImage(data.prismicBlog.data.banner_image.localFile);
  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <BlogContainer
        parentText="Blog"
        title={title}
        relatedContent={relatedContent}
        path={path}
      >
        <div className="blog-div flex flex-col">
          <p className="mb-10 text-3xl">{title}</p>
          {image && (
            <GatsbyImage image={image} alt="banner image" className="mb-7" />
          )}
          {data.prismicBlog.data.body.map((content, index) => {
            return (
              <SliceContent
                key={`content${index}`}
                data={content}
                index={index}
              />
            );
          })}
        </div>
      </BlogContainer>
    </div>
  );
};

export const blogTemplateQuery = graphql`
  query($id: String!) {
    prismicBlog(id: { eq: $id }) {
      uid
      data {
        banner_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        banner_title {
          text
        }
        related_content_1 {
          uid
        }
        related_content_2 {
          uid
        }
        related_content_3 {
          uid
        }
        body {
          ... on PrismicBlogBodyText {
            id
            primary {
              content {
                html
                raw
              }
            }
            internal {
              type
            }
          }
          ... on PrismicBlogBodyBulletList {
            id
            primary {
              content {
                html
                raw
              }
            }
            internal {
              type
            }
          }
          ... on PrismicBlogBodyHeader {
            id
            primary {
              content {
                html
                raw
              }
            }
            internal {
              type
            }
          }
          ... on PrismicBlogBodyImageGallery {
            id
            primary {
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            internal {
              type
            }
          }

          ... on PrismicBlogBodyParagraphWithImageRight {
            id
            primary {
              text {
                html
                raw
              }
              paragraph_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            internal {
              type
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
