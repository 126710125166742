import React from "react";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";
import ContentContainer from "../components/container/ContentContainer";

//Downloadable Forms
import resaleCertificate from "../downloads/2018/02/CAResaleCertificate.pdf";
import codApplication from "../downloads/2022/NWP-So-Cal-COD-Application-2022-NEW.pdf";
import applicationforCreditNWPSoCal from "../downloads/2022/Application-for-Credit-NWP-So-Cal.pdf";
import TitleDiv from "../components/controls/TitleDiv";
import { StaticImage } from "gatsby-plugin-image";
import { VscFilePdf } from "react-icons/vsc";
import Layout from "../components/layout/Layout";

const links = [
  {
    text: "National Wood Products, Inc. Headquarters",
    url: "https://nationalwood.com/",
    image: (
      <StaticImage
        src="../images/NWP-LOGO-NEW-COLOR.png"
        placeholder="blurred"
        quality={100}
        alt="National Wood Products"
      />
    ),
  },
  {
    text: "Emporio Skin",
    url: "https://emporioskin.it/",
    image: (
      <StaticImage
        src="../images/Emporio-Skin-logo.jpg"
        alt="Emporio Skin"
        width={100}
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Banks Hardwoods",
    url: "https://bankshardwoods.com/",
    image: (
      <StaticImage
        src="../images/bankshardwoods.png"
        alt="Banks Hardwoods"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Devereaux",
    url: "https://devereauxsawmill.com/",
    image: (
      <StaticImage
        src="../images/Devereaux.png"
        alt="Devereaux"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Northwest Hardwoods",
    url: "https://northwesthardwoods.com/",
    image: (
      <StaticImage
        src="../images/northwest.png"
        alt="Northwest Hardwoods"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Baillie",
    url: "https://www.baillie.com/",
    image: (
      <StaticImage
        src="../images/baillie.png"
        alt="Baillie"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Roseburg",
    url: "https://www.roseburg.com/",
    image: (
      <StaticImage
        src="../images/Roseburg.png"
        alt="Roseburg"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "States Industries",
    url: "https://www.statesind.com/",
    image: (
      <StaticImage
        src="../images/States.png"
        alt="States Industries"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Inter Global Forest",
    url: "https://www.interglobalforest.com/",
    image: (
      <StaticImage
        src="../images/IGF.png"
        alt="Inter Global Forest"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Granite Valley Forest Products",
    url: "https://www.granitevalley.com/",
    image: (
      <StaticImage
        src="../images/granite-valley-2.png"
        alt="Granite Valley"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    text: "Pollmeier",
    url: "https://www.pollmeier.com/",
    image: (
      <StaticImage
        src="../images/Pollmeier.png"
        alt="Pollmeier"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
];
// Version 2
const FormsPage = ({ location }) => {
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData title="Forms" />
        <Header />
        <TitleDiv location={location} title="Forms & Links" />

        <ContentContainer className="w-full px-4 md:px-0 max-w-content mt-7">
          <div className="w-full max-w-2xs md:max-w-md bg-gray8 text-white py-4 flex justify-center rounded-tr-50">
            <h2>Forms</h2>
          </div>
        </ContentContainer>
        <ContentContainer className="w-full px-4 md:px-0 max-w-content bg-white md:bg-gray2">
          <div className="pl-0 md:pl-10 sm:pl-32 flex flex-col pt-14 pb-28 space-y-3 ">
            <div>
              <a
                href={resaleCertificate}
                target="_blank"
                rel="noreferrer"
                className="text-blue3"
              >
                California Resale Certificate
              </a>
              <VscFilePdf className="ml-2 text-lg inline" />
            </div>
            <div>
              <a
                href={codApplication}
                target="_blank"
                rel="noreferrer"
                className="text-blue3"
              >
                NWP Southern California COD Application
              </a>
              <VscFilePdf className="ml-2 text-lg inline" />
            </div>
            <div>
              <a
                href={applicationforCreditNWPSoCal}
                target="_blank"
                rel="noreferrer"
                className="text-blue3"
              >
                Southern California Application for Credit
              </a>
              <VscFilePdf className="ml-2 text-lg inline" />
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full px-4 md:px-0 max-w-content mt-7">
          <div className="w-full max-w-2xs md:max-w-md bg-gray8 text-white py-4 flex justify-center rounded-tr-50">
            <h2 className="hidden md:block">Industry Websites</h2>
            <h2 className="block md:hidden">Links</h2>
          </div>
        </ContentContainer>
        <ContentContainer className="flex flex-col w-full px-4 md:px-0 max-w-content bg-white md:bg-gray2 mb-10">
          <div className="text-gray2 text-base md:text-xl pt-2 md:pt-10 text-left md:text-center">
            NWP headquarters and some of our valued suppliers:
          </div>
          <div className="px-4 lg:px-32 grid grid-cols-3 lg:grid-cols-4 pt-14 pb-28 gap-x-6 md:gap-x-4 gap-y-2 md:gap-y-10 lg:gap-14">
            {links.map((link, index) => {
              return (
                <a
                  key={`links${index}`}
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-col gap-y-6"
                >
                  <div className="flex items-center justify-center w-full h-24 md:h-36">
                    {link.image}
                  </div>
                  <div className="flex flex-row items-center justify-center text-sm lg:text-base">
                    <p className="text-center">{link.text}</p>
                  </div>
                </a>
              );
            })}
          </div>
        </ContentContainer>
      </div>
    </Layout>
  );
};

export default FormsPage;
