import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContactExpert from "../../../components/controls/ContactExpert";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { Link, useStaticQuery, graphql } from "gatsby";
import TitleSubDiv from "../../../components/controls/TitleSubDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Layout from "../../../components/layout/Layout";

const ThermofusedLaminates = () => {
  const heroImage = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "thermofused-laminates-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      fabrics: file(
        relativePath: { eq: "thermofused-laminates/collections/fabrics.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      cements: file(
        relativePath: { eq: "thermofused-laminates/collections/cements.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      stones: file(
        relativePath: { eq: "thermofused-laminates/collections/stones.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      woods: file(
        relativePath: { eq: "thermofused-laminates/collections/woods.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      metals: file(
        relativePath: { eq: "thermofused-laminates/collections/metals.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);

  const path = ["Thermofused Laminates in Southern California"];
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="Thermofused Laminates - So Cal"
          description="Thermofused Laminates is a collection of design surfaces inspired by the tradition of elegance and design, interpreting the trends of the world of furnishing and interior design sector as a testimony of Italian style." 
        />
        <Header />
        <TitleSubDiv
          parentText="Products"
          title="Thermofused Laminates in Southern California"
          className="mb-8"
          path={path}
        />
        <ContentContainer
          className="w-full max-w-content md:mx-5 transform scale-x-flip flex items-center justify-center"
          opacityClassName="bg-opacity-60"
          image={heroImage.backGround}
        >
          <div className="text-white my-20 transform scale-x-flip text-center text-3xl gap-y-4 w-full max-w-2xl">
            <p className="font-bold">Thermofused Laminates from Emporio Skin</p>
            <p>The Charm of the Material</p>
            <p className="text-base pt-5">
              A collection of design surfaces inspired by the tradition of elegance and design, interpreting the trends of the world of furnishing and interior design sector as a testimony of Italian style.
            </p>
            <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
              Request free quote
            </ButtonAction>
          </div>
        </ContentContainer>

        <ContentContainer
          containerClassName="w-full mt-10 md:mt-24"
          className="flex flex-col px-0 md:px-4 w-full max-w-7xl"
        >
          <div className="flex flex-col sm:flex-row">
            <div className="w-full sm:w-7/12 p-5 sm:pl-0 sm:pr-8">
              <p className="text-3xl font-bold pb-12 text-center md:text-left">
                Emporio Skin Thermofused Laminates
              </p>
              <p className="mb-6">
                Emporio Skin: A new line of Premium European Thermo Fused Laminate. The charm of the material and endless combinations that will define your space.
              </p>
              <p className="mb-6 font-bold">
                Emporio Skin, the top-quality excellence of Kronospan production, entirely Made in Italy!
              </p>
              <p className="mb-6">Emporio Skin embodies the Made in Italy quality of craftmanship, durability and  beauty. The attention to detail that goes into all of our products is a statement to our high standards.
              </p>
              <p>
                National Wood Products is your local supplier of Thermofused Laminates
                throughout Southern California.
              </p>
            </div>
            <div className="relative w-full sm:w-5/12">
              <StaticImage
                src="../../../images/thermofused-laminates/emporio-skin-section.png"
                alt="Emporio Skin"
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />
              <div className="absolute bottom-4 right-4">
                <StaticImage
                  src="../../../images/thermofused-laminates/made-in-italy-badge.jpg"
                  alt="Made in Italy"
                  placeholder="blurred"
                  quality={100}
                  width={100}
                  className="rounded-full"
                  layout="constrained"
                />
              </div>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full max-w-content mt-8 md:mt-16">
          <h2 className="text-center bg-gray1 py-8">COLLECTIONS</h2>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 md:gap-5 sm:gap-11 mt-0 md:mt-8">
            <Link to="./fabrics">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.fabrics}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center">
                  Fabrics Collection
                </p>
              </ContentContainer>
            </Link>
            <Link to="./cements">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.cements}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center">
                  Cements Collection
                </p>
              </ContentContainer>
            </Link>
            <Link to="./woods">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.woods}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center">
                  Woods Collection
                </p>
              </ContentContainer>
            </Link>
            <Link to="./metals">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.metals}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center">
                  Metals Collection
                </p>
              </ContentContainer>
            </Link>
          </div>
          <div>
            <p className="text-center text-lg md:text-xl mt-8">ACCESSORIES: 22mm x 328’ Edgebanding; 44mm x 328’ Edgebanding; 52” x 120” HPL</p>
          </div>
          <div className="w-2/12 mx-auto">
            <a href="https://emporioskin.it/wp-content/uploads/2023/03/DecorCollection.pdf" target="_blank" rel="noopener noreferrer">
              <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center mt-8">
                Decor Collection
              </p>
            </a>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full max-w-content mt-16 mb-6">
          <div className="bg-gray2 text-center flex flex-col gap-y-10 py-10 sm:py-24 px-5 xl:px-102 text-lg md:text-xl">
            <i>
              “Furniture makes a simple building a home, imprinting it with personality, warmth and meaning. Choose a collection of value that makes it a perfect fit for those who live in it.<br/> Choose Emporio Skin.”
            </i>
            <p>- Emporio Skin</p>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default ThermofusedLaminates;
