import React from "react";
import ContentContainer from "../../../../components/container/ContentContainer";
import ContactExpert from "../../../../components/controls/ContactExpert";
import TitleDiv from "../../../../components/controls/TitleDiv";
import Header from "../../../../components/sections/Header";
import HeadData from "../../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import SimpleReactLightbox from "simple-react-lightbox";
import Thumbnail from "../../../../components/controls/Thumbnail";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Layout from "../../../../components/layout/Layout";

const MetalsPage = () => {
  const path = ["Thermofused Laminates in Southern California", "The Metals Collection"];

  const gallery = useStaticQuery(graphql`
    {
      metals: file(
        relativePath: { eq: "thermofused-laminates/metals/metals-section.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      arenaThumb: allFile(
        filter: {
          relativeDirectory: { eq: "thermofused-laminates/metals/arena" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      arenaHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "thermofused-laminates/metals/arena/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }      
    }
  `);

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="The Metals Collection" 
          description="The symbol of the creativity of humankind which is the source behind the everyday objects that define our environments and at the same time become art. The expressive and resistant nature of metals is perfect for creating decors with a modern, refined and original effect."
        />
        <Header />
        <TitleDiv
          parentText="Products"
          title="The Metals Collection"
          className="mb-14"
          path={path}
        />

        <ContentContainer className="bg-gray5 text-center py-12">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="bg-gray2 py-12 mb-9">
          <div className="flex flex-col text-center items-center md:px-20">
            <StaticImage
              src="../../../images/thermofused-laminates/made-in-italy-badge.jpg"
              alt="Made in Italy"
              placeholder="blurred"
              quality={100}
              width={100}
              className="rounded-full mb-4 shadow-lg" 
              layout="constrained"
            />
            <p className="text-3xl">The Metals Collection</p>
            <hr className="border-blue1 border-t-2 mt-4 w-56" />
            <div className="flex flex-col lg:flex-row flex-wrap w-full pt-14 gap-x-0 gap-y-4 sm:gap-y-11">
              <BackgroundImage
                className="w-full min-h-52 lg:w-1/2 bg-left-top"
                {...convertToBgImage(getImage(gallery.metals))}
              />
              <div className="w-full lg:w-1/2 md:bg-gray1 py-10 md:py-20 px-0 md:px-12 text-left">
                <p>
                  Metals decors will enhance any contemporary space allowing for individuality and personal expression like no other.
                </p>
              </div>              
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left uppercase">Arena</p>
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 mb-9">
          <div className="py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 md:grid-cols-6 gap-8 text-center items-start">
                {gallery.arenaThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.arenaHiRes.edges[index].node
                          .childImageSharp.gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
          </div>
        </ContentContainer>              

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default MetalsPage;
