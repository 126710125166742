import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { MdLocationOn } from "react-icons/md";

const LocationLink = styled.a`
  // text-decoration: none !important;
  color: inherit !important;
`;

const HomeHeroUpdate = () => {
  return (
    <div className="hidden md:grid relative bg-green-900 -mt-32">
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 800,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Home Hero Background"
        src={
          "../../images/nwp-banner.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 md:w-7/12 text-white">
          <div className="w-full flex items-center justify-center">
            <div className="flex container items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-8 sm:space-y-14">
                <h1 className="font-raleway md:w-12em uppercase font-black text-white text-center">
                  National Wood Products So Cal
                </h1>
                <span className="w-full max-w-lg text-center">
                  Your wholesale hardwood lumber, hardwood plywood, lacquered
                  panel & melamine supplier in Southern California
                </span>
                <div>
                  <Link className="button-action" to="contact/">
                    <button className="bg-actionBlue hover:bg-opacity-50 text-white px-12 inline-block py-3 text-lg rounded-full text-center">Request Free Quote</button>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center mx-auto mt-8 lg:mt-14 text-sm lg:text-xl text-white text-center">
                <span>Our Southern California Location</span>
                <div className="text-red-600 text-4xl rotate">
                  <MdLocationOn />
                </div>
                <LocationLink
                  href="https://goo.gl/maps/xybmsxwNP374L1ub6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>1 Latitude Way, </span>
                  <span>Corona, CA 92881</span>
                </LocationLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroUpdate;
