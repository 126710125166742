import React from "react";
import { FaCircle } from "react-icons/fa";
import JotformEmbed from "react-jotform-embed";
import GoogleMap from "../sections/GoogleMap";

const ContactFormNew = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row-reverse gap-x-4 w-full pb-10 ">
      <div className="md:w-1/2 pt-0 md:pt-14 px-2 bg-gray6 pb-12 sm:pb-0">
        <JotformEmbed src="https://form.jotform.com/212761589417060" />
      </div>
      <div className="flex-col space-y-1 md:w-1/2 pt-10 md:pt-14 text-left bg-gray6 md:bg-gray1 px-5 lg:px-10">
        <h3 className="font-bold text-2xl mb-5">Get in touch</h3>
        <p className="pb-9">
          Have a question or comment on one of our products? Feel free to use
          the form or contact us by phone, and an NWP representative will get
          back to you as soon as possible.
        </p>
        <h3 className="font-bold">The Office NWP Southern California</h3>
        <ul className="pb-9 flex flex-col">
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" /> Address: <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" target="_blank" rel="noreferrer">1 Latitude Way, Corona, CA 92881</a>
          </li>
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" />
            {` Phone: `}
            <a href="tel:909-287-7906" className="text-blue3 ml-1">
              (909) 287-7906
            </a>
          </li>
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" /> Email:
            <a href="mailto:mhernandez@nationalwood.com" className="text-blue3 ml-1">
              mhernandez@nationalwood.com
            </a>
          </li>

          <div className="mt-6">
            <GoogleMap />
          </div>
        </ul>
        <h3 className="font-bold">Business Hours</h3>
        <ul className="pb-9 flex flex-col">
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" /> Monday – Friday 7:30am to 5pm
          </li>
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" /> Saturday – Closed
          </li>
          <li className="inline-flex items-center">
            <FaCircle className="mr-2 text-2xs" /> Sunday – Closed
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactFormNew;
