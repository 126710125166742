// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-category-[tag]-js": () => import("./../../../src/pages/blog/category/[tag].js" /* webpackChunkName: "component---src-pages-blog-category-[tag]-js" */),
  "component---src-pages-blog-category-js": () => import("./../../../src/pages/blog/category.js" /* webpackChunkName: "component---src-pages-blog-category-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-prismic-blog-uid-js": () => import("./../../../src/pages/blog/{prismicBlog.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-uid-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-employment-application-index-js": () => import("./../../../src/pages/jobs/employment-application/index.js" /* webpackChunkName: "component---src-pages-jobs-employment-application-index-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-hardwood-lumber-index-js": () => import("./../../../src/pages/products/hardwood-lumber/index.js" /* webpackChunkName: "component---src-pages-products-hardwood-lumber-index-js" */),
  "component---src-pages-products-hardwood-plywood-index-js": () => import("./../../../src/pages/products/hardwood-plywood/index.js" /* webpackChunkName: "component---src-pages-products-hardwood-plywood-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-lacquered-boards-index-js": () => import("./../../../src/pages/products/lacquered-boards/index.js" /* webpackChunkName: "component---src-pages-products-lacquered-boards-index-js" */),
  "component---src-pages-products-mdf-index-js": () => import("./../../../src/pages/products/mdf/index.js" /* webpackChunkName: "component---src-pages-products-mdf-index-js" */),
  "component---src-pages-products-melamine-panels-index-js": () => import("./../../../src/pages/products/melamine-panels/index.js" /* webpackChunkName: "component---src-pages-products-melamine-panels-index-js" */),
  "component---src-pages-products-thermofused-laminates-cements-index-js": () => import("./../../../src/pages/products/thermofused-laminates/cements/index.js" /* webpackChunkName: "component---src-pages-products-thermofused-laminates-cements-index-js" */),
  "component---src-pages-products-thermofused-laminates-fabrics-index-js": () => import("./../../../src/pages/products/thermofused-laminates/fabrics/index.js" /* webpackChunkName: "component---src-pages-products-thermofused-laminates-fabrics-index-js" */),
  "component---src-pages-products-thermofused-laminates-index-js": () => import("./../../../src/pages/products/thermofused-laminates/index.js" /* webpackChunkName: "component---src-pages-products-thermofused-laminates-index-js" */),
  "component---src-pages-products-thermofused-laminates-metals-index-js": () => import("./../../../src/pages/products/thermofused-laminates/metals/index.js" /* webpackChunkName: "component---src-pages-products-thermofused-laminates-metals-index-js" */),
  "component---src-pages-products-thermofused-laminates-woods-index-js": () => import("./../../../src/pages/products/thermofused-laminates/woods/index.js" /* webpackChunkName: "component---src-pages-products-thermofused-laminates-woods-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */)
}

