import React from "react";
import loadable from "@loadable/component";
import pMinDelay from 'p-min-delay'

import HeadData from "../data/HeadData";
import ProductGalleryNew from "../components/home/ProductGalleryNew";
import PreviewAbout from "../components/home/PreviewAbout";
import ProductFeatureNew from "../components/home/ProductFeatureNew";
import ValuedSuppliers from "../components/controls/Prismic/ValuedSuppliers";
import Advantages from "../components/home/Advantages";
import ContactExpert from "../components/controls/ContactExpert";
import Hiring from "../components/home/Hiring";
import SatisfiedCustomerPlaceholder from "../components/controls/SatisfiedCustomerPlaceholder";
import Layout from "../components/layout/Layout";
import Header from "../components/sections/Header";
import HomeHeroUpdate from "../components/hero/HomeHeroUpdate";
import HomeHeroMobile from "../components/hero/HomeHeroMobile";

const SatisfiedCustomerNew = loadable(() =>
  pMinDelay(import("../components/controls/SatisfiedCustomerNew"), 3000),{
    fallback:
    <SatisfiedCustomerPlaceholder />
  }
);

const IndexPage = () => {

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData isHome />

        <Header isHome />

        <HomeHeroUpdate />

        <HomeHeroMobile />

        <ProductGalleryNew />

        <PreviewAbout />

        <ProductFeatureNew />

        <ValuedSuppliers />

        <Advantages />

        <ContactExpert />

        <SatisfiedCustomerNew />

        <Hiring />
      </div>
    </Layout>
  );
};

export default IndexPage;
