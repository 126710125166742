import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContactExpert from "../../../components/controls/ContactExpert";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import TitleDiv from "../../../components/controls/TitleDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Thumbnail from "../../../components/controls/Thumbnail";
import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "../../../components/layout/Layout";

const MelaminePanelsPage = () => {
  const images = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "kronospan-laminates.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      solids: allFile(
        filter: { relativeDirectory: { eq: "melamine-panels/solids" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      woods: allFile(
        filter: { relativeDirectory: { eq: "melamine-panels/wood-grains" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);
  const path = ["Melamine Panels and Melamine Boards in Southern California"];

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="Melamine Panels and Boards - So Cal" 
          description="Durable and affordable panels for your next project? Check out our high-quality Melamine Panels! Perfect for cabinets, shelves, and more. Call now!"
        />
        <Header />

        <TitleDiv
          parentText="Products"
          title="Melamine Panels and Melamine Boards in Southern California"
          className="mb-8"
          path={path}
        />

        <ContentContainer
          className="w-full max-w-content md:mx-5 transform bg-center flex items-center justify-center"
          opacityClassName="bg-opacity-60"
          image={images.backGround}
        >
          <div className="text-white my-20 transform text-center text-3xl space-y-4 w-full px-5 xl:px-72">
            <p className="font-bold">
              Melamine Panels & Boards from Kronospan
            </p>
            <p className="text-base pt-5">
              Established in 1897 as an Austrian family company, Kronospan is a
              leading wood-based panel manufacturer using advanced technology, and
              as such we have pioneered many of our industry's key advances and
              will continue to lead product development and innovation into the
              future.
            </p>
            <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
              Request free quote
            </ButtonAction>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 text-center py-12 mt-14">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 py-2 pb-28 mb-9 flex flex-col items-center gap-y-11">
          <div className="flex flex-col">
            <div className="mt-20 text-center flex flex-col items-center justify-center">
              <p className="text-3xl font-raleway font-bold">Solids</p>
              <hr className="border-blue4 border-t-2 w-52 mt-3 mb-2" />
              <p>Other colors available by special order. Call for lead time.</p>
              <SimpleReactLightbox>
                <div className="grid gap-y-12 gap-x-5 sm:gap-x-14 grid-cols-4 mt-16 px-5 text-center w-full max-w-screen-md items-start">
                  {images.solids.edges.map((thumbnail, index) => {
                    return (
                      <Thumbnail
                        key={`gallery${index}`}
                        hiRes={
                          thumbnail.node.childImageSharp.gatsbyImageData.images
                            .fallback.src
                        }
                        name={thumbnail.node.name}
                        thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                      />
                    );
                  })}
                </div>
              </SimpleReactLightbox>
            </div>
            <div className="mt-20 text-center flex flex-col items-center justify-center">
              <p className="text-3xl font-raleway font-bold">Woodgrains</p>
              <hr className="border-blue4 border-t-2 w-52 mt-3 mb-2" />

              <SimpleReactLightbox>
                <div className="grid gap-y-12 gap-x-5 sm:gap-x-14 grid-cols-3  mt-16 px-5 text-center  w-full max-w-screen-md">
                  <div>&nbsp;</div>
                  {images.woods.edges.map((thumbnail, index) => {
                    return (
                      <Thumbnail
                        key={`gallery${index}`}
                        hiRes={
                          thumbnail.node.childImageSharp.gatsbyImageData.images
                            .fallback.src
                        }
                        name={thumbnail.node.name}
                        thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                        thumbnailClassName=""
                        descClassName=" "
                      />
                    );
                  })}
                  <div>&nbsp;</div>
                </div>
              </SimpleReactLightbox>
              <p className="mt-14 text-sm md:text-base max-w-2xl">
                Kronospan North America, offers a unique "Express Program" out of
                their Eastaboga, Alabama facility, with a multitude of colors
                available in 34 sheet (full unit) quantities of ¾" 5' x 8' panels
                and a 4-6 week lead time (subject to freight and product
                availability at the time of the order). Call us for more
                information.
              </p>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray2 py-10 sm:py-24 mb-9 flex text-center px-5 xl:px-102 space-y-8 text-lg md:text-xl">
          <i className="font-normal">
            “TFL is an essential material in the cabinet, furniture, and interior
            design industries. Made from particleboard covered with a decorative
            paper impregnated with melamine resin, it’s the most widely used
            material and is available in various finishes and colors.”
          </i>
          <i>- Kronospan</i>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default MelaminePanelsPage;
