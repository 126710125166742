import React from "react";
import ContentContainer from "../../../../components/container/ContentContainer";
import ContactExpert from "../../../../components/controls/ContactExpert";
import TitleDiv from "../../../../components/controls/TitleDiv";
import Header from "../../../../components/sections/Header";
import HeadData from "../../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import SimpleReactLightbox from "simple-react-lightbox";
import Thumbnail from "../../../../components/controls/Thumbnail";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Layout from "../../../../components/layout/Layout";

const FabricsPage = () => {
  const path = ["Thermofused Laminates in Southern California", "The Fabrics Collection"];

  const gallery = useStaticQuery(graphql`
    {
      fabrics: file(
        relativePath: { eq: "thermofused-laminates/fabrics/fabrics-section.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      tessutoThumb: allFile(
        filter: {
          relativeDirectory: { eq: "thermofused-laminates/fabrics/tessuto" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      tessutoHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "thermofused-laminates/fabrics/tessuto/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
      technoThumb: allFile(
        filter: {
          relativeDirectory: { eq: "thermofused-laminates/fabrics/techno" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      technoHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "thermofused-laminates/fabrics/techno/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
      
    }
  `);

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="The Fabrics Collection" 
          description="Aesthetics, Made in Italy, and functionality are combined to create fabric décors that transform any room into a cosy and personal space."
        />
        <Header />
        <TitleDiv
          parentText="Products"
          title="The Fabrics Collection"
          className="mb-14"
          path={path}
        />

        <ContentContainer className="bg-gray5 text-center py-12">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="bg-gray2 py-12 mb-9">
          <div className="flex flex-col text-center items-center md:px-20">
            <StaticImage
              src="../../../images/thermofused-laminates/made-in-italy-badge.jpg"
              alt="Made in Italy"
              placeholder="blurred"
              quality={100}
              width={100}
              className="rounded-full mb-4 shadow-lg" 
              layout="constrained"
            />
            <p className="text-3xl">The Fabrics Collection</p>            
            <hr className="border-blue1 border-t-2 mt-4 w-56" />
            <div className="flex flex-col lg:flex-row flex-wrap w-full pt-14 gap-x-0 gap-y-4 sm:gap-y-11">
              <BackgroundImage
                className="w-full min-h-52 lg:w-1/2 bg-left-top"
                {...convertToBgImage(getImage(gallery.fabrics))}
              />              
              <div className="w-full lg:w-1/2 md:bg-gray1 py-10 md:py-20 px-0 md:px-12 text-left">
                <p>
                  Fabric decors that bring cozy warmth making any room more of a personal space, giving each room your own refined touch.
                </p>
              </div>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left uppercase">Tessuto</p>          
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 mb-9">
          <div className="py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 md:grid-cols-6 gap-8 text-center items-start">
                {gallery.tessutoThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.tessutoHiRes.edges[index].node
                          .childImageSharp.gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left uppercase">Techno</p>
        </ContentContainer>
        <ContentContainer className="bg-gray2 mb-9 ">
          <div className="py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 md:grid-cols-6 gap-8 text-center items-start">
                {gallery.technoThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.technoHiRes.edges[index].node.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
          </div>
        </ContentContainer>        

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default FabricsPage;
